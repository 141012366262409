import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"

const Qualys = () => {
  const data = useStaticQuery(graphql`
    query imagesQualys {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `) 
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Partner de Qualys VMDR en Seguridad Informática | Qualoom</title>
        <meta name="title" content="Partner de Qualys VMDR en Seguridad Informática | Qualoom"/>
        <meta name="description" content="En alianza con Qualys VMDR, ofrecemos Gestión, Detección y Respuesta a Vulnerabilidades críticas ¡Infórmate sobre una de las Herramientas más completas!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/seguridad-informatica/qualys/"/>
        <link rel="canonical" href="https://www.qualoom.es/seguridad-informatica/qualys/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/seguridad-informatica/qualys/"/>
        <meta property="og:title" content="Partner de Qualys VMDR en Seguridad Informática | Qualoom"/>
        <meta property="og:description" content="En alianza con Qualys VMDR, ofrecemos Gestión, Detección y Respuesta a Vulnerabilidades críticas ¡Infórmate sobre una de las Herramientas más completas!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/seguridad-informatica/qualys/"/>
        <meta property="twitter:title" content="Partner de Qualys VMDR en Seguridad Informática | Qualoom"/>
        <meta property="twitter:description" content="En alianza con Qualys VMDR, ofrecemos Gestión, Detección y Respuesta a Vulnerabilidades críticas ¡Infórmate sobre una de las Herramientas más completas!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/seguridad-informatica/qualys/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Seguridad informática", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/", "name": "Seguridad informática"}},
              {"@type":"ListItem", "position":"3", "name":"Qualys VMDR: Gestión integral, detección y respuesta a vulnerabilidades", "item": {"@id": "https://www.qualoom.es/seguridad-informatica/qualys/", "name": "Qualys VMDR: Gestión integral, detección y respuesta a vulnerabilidades"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Qualys y Qualoom: Una aliance por la seguridad de las organizaciones</h1>
              <p>Qualoom Expertise Technology, a partir de su alianza con Qualys como partner de confianza, pone a disposición de sus socios y clientes una de las herramientas más completas del mercado, Qualys VMDR con el objetivo de analizar cualquier ecosistema on-premise, cloud o híbrido, realizar el descubrimiento, evaluación, detección y categorización de la información obtenida para una posterior remediación de las vulnerabilidades encontradas.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Qualys VMDR: Gestión integral, detección y respuesta a vulnerabilidades</h2><hr></hr>
          <div className="section-main">
            <div className="section-img vmdr"></div>
            <div className="section-text">
              <p>Qualys VMDR y WAS son dos de las herramientas más completas del mercado a la hora de identificar vulnerabilidades en los sistemas de información de las organizaciones y en mostrarla a sus responsables para que tomen las decisiones más adecuadas de la manera más eficiente.</p><br/>
              <h3>Qualys VMDR</h3>
              <p>Qualys VMDR tiene el objetivo de analizar el perímetro interno de la organización y analizar todos los endpoint públicos que la empresa tenga expuestos encontrando los distintos vectores de ataque..</p>
            </div>
          </div>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Qualoom, partner de confianza</h2><hr></hr>
          <h3>Detección y corrección de vulnerabilidades críticas en tiempo real</h3>
          <p>No esperar a la materialización de un riesgo es la manera más diligente de corregir una vulnerabilidad y para ello es necesario contar con las herramientas adecuadas y con un equipo de especialistas capaces de interpretar la información facilitada y para la toma de decisiones que resuelvan dichas vulnerabilidades.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Por qué tu negocio necesita los servicios de<br/>Qualoom y Qualys VMDR?</h2><hr></hr>
          <p>VMDR proporciona ciclos de protección continuos a través de un panel único con flujos de trabajo de orquestación integrados y detección de vulnerabilidades en tiempo real para priorización, remediación y auditoría en entornos de TI híbridos.</p><br/>
          <h3>Las ventajas de Qualys VMDR</h3>
          <ul>
            <li><b>Gestión de los riesgos en ciberseguridad. </b>Cuantifica el riesgo a través de las vulnerabilidades y su naturaleza para ayudar a tu organización a reducir de forma proactiva la exposición al riesgo y realizar un seguimiento a lo largo del tiempo.</li>
            <li><b>Automatización mediante flujos de trabajo sin código. </b>Ahorra tiempo automatizando y orquestando las tareas operativas para la gestión de vulnerabilidades.</li>
            <li><b>Análisis de las vulnerabilidades y los errores de configuración. </b>Detecta automáticamente las vulnerabilidades y los errores críticos según los puntos de referencia del Centro de Seguridad de Internet (CIS).</li>
            <li><b>Resolución rápida de los errores a escala. </b>Qualys VMDR ofrece la capacidad de abordar rápidamente los errores y vulnerabilidades detectados a gran escala, lo que permite a las organizaciones mejorar la eficiencia en la corrección de problemas de seguridad en toda su infraestructura tecnológica.</li>
          </ul>
        </div>
      </div>
      <Footer />
      </div>
  )
}

export default Qualys